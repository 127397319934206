import React, { useEffect, useState } from "react";
import { Col, Row, Button, Modal, Form } from "react-bootstrap";
import { useHistory, NavLink } from "react-router-dom";
import { base_url } from "../Helpers/request";
import "react-toastify/dist/ReactToastify.css";
import { isMobileOnly, isTablet } from "react-device-detect";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { CircularProgress, TextField } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import axios from "axios";
import swal from "sweetalert";
import { cartlengthvalue } from "../Reducer/cartReducer";
import { useDispatch, useSelector } from "react-redux";
import { Wishlengthvalue } from "../Reducer/wishlistReducer";
import { loginDrawer, sessionId, Setuser } from "../Reducer/homeReducer";
import { BiUser } from "react-icons/bi";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { toast } from "react-toastify";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login";
import { gapi } from "gapi-script";
import { FaFacebook } from "react-icons/fa6";

const FacebookLoginComponent = () => {
  const history = useHistory();
  const session_Id = useSelector(
    (state) => state.persistedReducer.home.session_id
  );
  const dispatch = useDispatch();

  useEffect(() => {
    // Load the Facebook SDK dynamically
    const loadFBScript = () => {
      if (
        !document.getElementById("facebook-jssdk") &&
        typeof window.FB === "undefined"
      ) {
        window.fbAsyncInit = function () {
          window.FB.init({
            appId: "2289601761409890", // Replace with your Facebook App ID
            cookie: true,
            xfbml: true,
            version: "v21.0", // Latest Graph API version
          });
        };

        const script = document.createElement("script");
        script.id = "facebook-jssdk";
        script.src =
          "https://connect.facebook.net/en_GB/sdk.js#xfbml=1&version=v21.0&appId=2289601761409890";
        script.async = true;
        script.defer = true;
        script.crossOrigin = "anonymous";
        document.body.appendChild(script);
      }
    };

    loadFBScript();
  }, []);

  const responseFacebook = (userInfo) => {
    const [first_name, last_name = ""] = userInfo?.name?.split(" ") || [];
    const data = {
      first_name: first_name,
      last_name: last_name,
      social_id: userInfo?.id,
      email: `${userInfo?.id ?? "NO_ID"}@facebook.com`,
      login_method: 3,
      session_id: session_Id ? session_Id : "",
      language: "EN",
      currency: "USD",
    };

    console.log("FACEBOOK", data, userInfo);

    axios
      .post(`${base_url}/user/socialLogin`, data, {
        headers: {
          // 'authorization':`Token e222085edc871220cae528152b73c1317ba2addf`,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.data.status == 1) {
          // window.location.reload(true);
          var user = {
            token: response.data.data.token,
            user_id: response.data.data.user_id,
            email: response.data.data.email,
            first_name: response.data.data.first_name,
            last_name: response.data.data.last_name,
            isLogin: true,
          };
          dispatch(cartlengthvalue(response.data.data.total_cart_count));
          dispatch(Wishlengthvalue(response.data.data.total_wish_count));
          dispatch(Setuser(user));
          dispatch(sessionId(""));
          // setEmail("");
          // setPassword("");
          // setButtonLoading(false);
          dispatch(loginDrawer(false));
        } else {
          // setButtonLoading(false);
          // setErrmsg(true);
          // setMsg(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleLogin = () => {
    window.FB.login(
      (response) => {
        if (response.status === "connected") {
          // User logged in and authorized
          console.log("Logged in!", response);

          // Fetch user details
          window.FB.api("/me", { fields: "id,name,email" }, (userInfo) => {
            // console.log("User Info:", userInfo);
            responseFacebook(userInfo);
          });
        } else {
          // User cancelled login or did not authorize
          console.log("Login cancelled or not authorized.");
        }
      },
      { scope: "public_profile" } // Request these permissions
    );
  };

  return (
    <>
      {/* Facebook login button */}
      {/* <div
        className="fb-login-button"
        data-width=""
        data-size=""
        data-button-type=""
        data-layout=""
        data-auto-logout-link="false"
        data-use-continue-as="false"
      ></div> */}
      <button className="rcs_fb_button" onClick={handleLogin}>
        <FaFacebook size={22} color="#5890ff" className="mr-4" />
        Sign in with Facebook
      </button>
    </>
  );
};

export default FacebookLoginComponent;
