import React from "react";
import Heading from "../ui/Heading";
import { Container } from "react-bootstrap";

const Insta = () => {
  return (
    <section className="insta-section ">
      <Container fluid className="showcase-container">
        <Heading>
          <h2>Social Media</h2>
          <p>
            Follow us on Instagram{" "}
            <a
              href="https://www.instagram.com/vivodiamonds/?hl=en"
              className="text-dark"
              target="_blank" rel="noreferrer"
            >
              <strong>@vivodiamonds</strong>
            </a>
          </p>
        </Heading>
        <iframe title="instagram"
          src="https://instagram.demobw.live/vivodiamonds-2/"
          frameborder="0"
          width="100%"
          class="insta-iframe"
        ></iframe>
      </Container>
    </section>
  );
};

export default Insta;
